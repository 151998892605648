"use client";

import { useState, useEffect } from 'react';
import { Radio, RadioGroup } from '@headlessui/react';
import { useRouter } from 'next/navigation'; 
import { signIn } from 'next-auth/react';
import Divider from '@/app/components/Divider';
import Footer from '@/app/components/Footer';
import Navbar from '@/app/components/Navbar';

export default function Login() {
  const [rolePath, setRolePath] = useState('employer'); // Default is employer
  const router = useRouter();

  // Handle Credential-based Login
  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
  
    // Use NextAuth signIn method for credentials
    const res = await signIn('credentials', {
      redirect: false, // Avoid automatic redirect to handle errors manually
      email,
      password,
      role: rolePath, // Send role for employee/employer distinction
    });
  
    if (res?.error) {
      console.error(res.error); // Handle login error (optional: show error message to user)
    } else {
      // Redirect based on the selected role
      const userRole = rolePath; // For credentials-based login, we know the rolePath
      if (userRole === 'employer') {
        router.push('/dashboards/employer');
      } else {
        router.push('/dashboards/candidate');
      }
    }
  };

  // Handle Google Sign-In
  const handleGoogleSignIn = async () => {
    localStorage.setItem('userRole', rolePath); // Save selected role
    if (rolePath === 'employer') {
      await signIn('google', { callbackUrl: '/dashboards/employer' }); // Redirect employer
    } else {
      await signIn('google', { callbackUrl: '/dashboards/candidate' }); // Redirect employee
    }
  };

  // Handle LinkedIn Sign-In
  const handleLinkedInSignIn = async () => {
    localStorage.setItem('userRole', rolePath); // Save selected role
    if (rolePath === 'employer') {
      await signIn('linkedin', { callbackUrl: '/dashboards/employer' }); // Redirect employer
    } else {
      await signIn('linkedin', { callbackUrl: '/dashboards/candidate' }); // Redirect employee
    }
  };

  // After the OAuth login completes, handle the role-based redirect
  useEffect(() => {
    const handleOAuthRedirect = () => {
      const userRole = localStorage.getItem('userRole'); // Retrieve the role after OAuth login
      if (userRole === 'employer') {
        router.push('/dashboards/employer');
      } else if (userRole === 'employee') {
        router.push('/dashboards/candidate');
      }
      localStorage.removeItem('userRole'); // Clean up after redirect
    };

    // Ensure router.asPath is defined before using it
    if (router?.asPath && router.asPath.startsWith('/auth/callback')) {
      handleOAuthRedirect();
    }
  }, [router]);

  return (
    <>
      <Navbar />
      <Divider />
      <div className="flex bg-amber-50 min-h-full flex-1 flex-col justify-center py-36 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Zaloguj się do swojego konta
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-orange-100 px-6 py-6 shadow sm:rounded-lg sm:px-12">
            {/* Radio button for role selection */}
            <div className="mt-4 mb-4 flex justify-center">
              <fieldset aria-label="Role Selection">
                <RadioGroup
                  value={rolePath}
                  onChange={setRolePath}
                  className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
                >
                  <Radio
                    value="employer"
                    className="cursor-pointer rounded-full px-2.5 py-1 text-gray-500 data-[checked]:bg-emerald-600 data-[checked]:text-white"
                  >
                    Pracodawca
                  </Radio>
                  <Radio
                    value="employee"
                    className="cursor-pointer rounded-full px-2.5 py-1 text-gray-500 data-[checked]:bg-emerald-600 data-[checked]:text-white"
                  >
                    Pracownik
                  </Radio>
                </RadioGroup>
              </fieldset>
            </div>

            {/* Login form */}
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Adres e-mail
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Hasło
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    autoComplete="current-password"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                  />
                  <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                    Pamiętaj mnie
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <a href="#" className="font-semibold text-emerald-600 hover:text-emerald-500">
                    Zapomniałeś hasła?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-emerald-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                >
                  Zaloguj
                </button>
              </div>
            </form>

            <div className="relative mt-10">
              <div aria-hidden="true" className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-orange-100 px-6 text-gray-900">Zaloguj się przez partnera</span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-2 gap-4">
              <button
                type="button"
                onClick={handleGoogleSignIn}
                className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
              >
                <svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
                  <path
                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                    fill="#EA4335"
                  />
                  <path
                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                    fill="#34A853"
                  />
                </svg>
                <span className="text-sm font-semibold leading-6">Google</span>
              </button>

              <button
                type="button"
                onClick={handleLinkedInSignIn}
                className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
              >
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                  <path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path>
                  <path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
                </svg>
                <span className="text-sm font-semibold leading-6">LinkedIn</span>
              </button>
            </div>

            <div className="text-center pt-8 leading-6">
              <a href="/register" className="font-semibold text-emerald-600 hover:text-emerald-500">
                Nie masz konta? Zarejestruj się!
              </a>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <Footer />
    </>
  );
}